<app-header></app-header>
<app-topheader></app-topheader>
<div class="main">
  <div class="loader" [@hidepreloader]="hidepreloaderState">
    <img class="loaderImg" src="assets/img/loader.gif">
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="boxM"></div>
    </div>
    <div class="col-md-3">
      <div class="boxM"></div>
    </div>
    <div class="col-md-3">
      <div class="boxM"></div>
    </div>
    <div class="col-md-3">
      <div class="boxM"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="boxM"></div>
    </div>
    <div class="col-md-3">
      <div class="boxM"></div>
    </div>
    <div class="col-md-3">
      <div class="boxM"></div>
    </div>
    <div class="col-md-3">
      <div class="boxM"></div>
    </div>
  </div>
</div>
