import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {User, UsersService} from '../users.service';
import {SubdomainsService} from '../subdomain.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {GoogleMapsModule} from '@angular/google-maps';
import moment from 'moment';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css'],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(10)),
            transition('end => start', animate(10))
        ]),
        trigger('showFilter', [
            state('start', style({
                display: 'none',
                opacity: 0
            })),
            state('end', style({
                display: 'block',
                opacity: 1
            })),
            transition('start => end', animate(50)),
            transition('end => start', animate(300))
        ]),
        trigger('showUserMob', [
            state('start', style({
                right: '-100%',
            })),
            state('end', style({
                right: '0',
            })),
            transition('start => end', animate(300)),
            transition('end => start', animate(300))
        ])
    ],
})

export class UsersComponent implements OnInit {

    constructor(private UsersService: UsersService, private translate: TranslateService, public router: Router, private route: ActivatedRoute, public SubdomainsService: SubdomainsService) {
        console.log('constructor');

        // this.route.queryParams.subscribe(params => {
        //   console.log('params.update' , params.update);
        //   if (params.update == 1) { this.getBookings(); }
        // });
    }

    zoom = 8;
    childrenActive = false;
    hidepreloaderState = 'start';
    showFilterState = 'start';
    showUserMobState = 'start';

    shouldUpdate = false;
    canClose = false;
    @ViewChild('mobCloser') mobCloser: ElementRef;

    users: User[] = [];
    promocodes = [];
    latlng = [];
    form: FormGroup;
    messageForm: FormGroup;

    pageMode = 'all';
    isAllUsers = true;
    isAdminUsers = false;
    isClientUsers = false;
    isAgentUsers = false;
    disableService = true;
    isAssistantUsers = false;

    // message
    messageShow = false;
    messageId = '0';
    messageUserName = '0';
    messageUserEmail = '0';
    messageUserPhone = '0';
    sendSmsText = 'Send SMS';
    sendEmailText = 'Send eMail';

    all = 0;
    admins = 0;
    agents = 0;
    partners = 0;
    clients = 0;

    promocode = new FormControl('0');

    today = moment(new Date().setHours(0, 0, 0, 0));

    getCreated(date) {
        console.log(moment(date), moment(new Date()));
        return moment(new Date(date));
    }

    ngOnInit() {
        console.log('promocode', this.promocode.value)
        this.SubdomainsService.aythWithAccessKey().subscribe(response => {
            console.log('Response aythWithAccessKey', response);
            if (response.status === 'ERROR') {
                this.SubdomainsService.gotoHomePage();
            }
            if (response.user_id > 0 && response.status === '1') {
                this.SubdomainsService.gotoConfirmCodePage();
            }
        });

        const activeChild = this.route.children.length;
        if (activeChild != 0) {
            this.showUserMobState = 'end';
        }
        setInterval(() => {
            const activeChild = this.route.children.length;
            if (activeChild == 0) {
                this.childrenActive = false;
            } else {
                this.childrenActive = true;
                if (this.UsersService.shouldUpdate == true) {
                    const formData = {...this.form.value};
                    console.log('formDataFromChildren - ', formData);
                    this.getUsers(this.pageMode, '&azza=' + formData.azza + '&status=' + formData.status + '&service=' + formData.service);
                }
            }
        }, 1000);

        setTimeout(() => {
            this.canClose = true;
        }, 1000);

        this.getUsers('all', '&azza=0&status=0&service=0');

        this.form = new FormGroup({
            azza: new FormControl('0'),
            status: new FormControl('0'),
            service: new FormControl('0'),
        });

        this.messageForm = new FormGroup({
            message: new FormControl('Hello ' + this.messageUserName),
        });
        // setTimeout (() => {
        //   this.animateLoader();
        // }, 500);
    }

    receiveMessage($event) {
        console.log('event', $event);
    }

    @HostListener('document:click', ['$event'])
    onClick(event: Event) {
        console.log('click');
        if (this.canClose) {
            if (this.mobCloser.nativeElement.contains(event.target)) {
                this.toggleMobUser();
                setTimeout(() => {
                    this.router.navigate(['/users']);
                }, 700);
            }
        }
    }

    addNew() {
        this.router.navigate(['/users/new']);
        this.toggleMobUser();
    }

    goToUser(id) {
        this.router.navigate(['/users/' + id]);
        this.toggleMobUser();
    }

    getUsers(roles, parameters) {
        console.log(roles + parameters);
        this.UsersService.getUsers(roles, parameters).subscribe(response => {
            console.log(response);
            this.users = response.users;
            this.promocodes = response.promocodes;
            // this.latlng = response.latlngs;
            console.log('Response users + codes', this.users, this.promocodes);
            if (this.pageMode == 'all') {
                this.all = this.users.length;
                this.admins = this.users.filter(x => x.role == '3').length;
                this.agents = this.users.filter(x => x.role == '2').length;
                this.partners = this.users.filter(x => x.role == '4').length;
                this.clients = this.users.filter(x => x.role == '1').length;
            }
            // console.log('Response response.latlngs', response.latlngs);
            response.latlngs.forEach(currentValue => {
                // this.latlng.push({
                //     id: currentValue.id, lat: parseFloat(currentValue.lat), lng: parseFloat(currentValue.lng)
                // });
                // console.log('Response latlng', currentValue);
                this.addMarker(currentValue.id, currentValue.lat, currentValue.lng);
            });
            // this.totalBookings = this.bookings.length;
            // this.gettotalRevenue(response);
            setTimeout(() => {
                this.animateLoader();
                console.log('this.latlng', this.latlng);
            }, 500);
        });
    }

    addMarker(id, lat, lng) {
        this.latlng.push({
            position: {
                lat: parseFloat(lat),
                lng: parseFloat(lng),
            },
            id: id,
        });
    }

    deleteUser(id) {
        this.animateLoader();
        console.log('delete user ', id);
        this.UsersService.deleteUser(id).subscribe(response => {
            console.log('Response delete', response);
            if (response.res) {
                if (this.isAllUsers) {
                    this.switchToAllUsers();
                } else if (this.isAdminUsers) {
                    this.switchToAdminUsers();
                } else if (this.isClientUsers) {
                    this.switchToClientUsers();
                } else if (this.isAgentUsers) {
                    this.switchToAgentUsers();
                } else if (this.isAssistantUsers) {
                    this.switchToAssistantUsers();
                }
            } else {
                this.animateLoaderBack();
            }
        });
    }

    switchToAllUsers() {
        this.animateLoaderBack();
        this.isAllUsers = true;
        this.isAdminUsers = false;
        this.isClientUsers = false;
        this.isAgentUsers = false;
        this.isAssistantUsers = false;
        this.pageMode = 'all';
        this.justclearFilters();
        this.getUsers('all', '&azza=0&status=0&service=0');
        this.disableService = true;
    }

    switchToAdminUsers() {
        this.animateLoaderBack();
        this.isAllUsers = false;
        this.isAdminUsers = true;
        this.isClientUsers = false;
        this.isAgentUsers = false;
        this.isAssistantUsers = false;
        this.pageMode = '3';
        this.justclearFilters();
        this.getUsers('3', '&azza=0&status=0&service=0');
        this.disableService = true;
    }

    switchToClientUsers() {
        this.animateLoaderBack();
        this.isAllUsers = false;
        this.isAdminUsers = false;
        this.isClientUsers = true;
        this.isAgentUsers = false;
        this.isAssistantUsers = false;
        this.pageMode = '1';
        this.justclearFilters();
        this.getUsers('1', '&azza=0&status=0&service=0');
        this.disableService = true;
    }

    switchToAgentUsers() {
        this.animateLoaderBack();
        this.isAllUsers = false;
        this.isAdminUsers = false;
        this.isClientUsers = false;
        this.isAgentUsers = true;
        this.isAssistantUsers = false;
        this.pageMode = '2';
        this.justclearFilters();
        this.getUsers('2', '&azza=0&status=0&service=0');
        this.disableService = false;
    }

    switchToAssistantUsers() {
        this.animateLoaderBack();
        this.isAllUsers = false;
        this.isAdminUsers = false;
        this.isClientUsers = false;
        this.isAgentUsers = false;
        this.isAssistantUsers = true;
        this.pageMode = '4';
        this.justclearFilters();
        this.getUsers('4', '&azza=0&status=0&service=0');
        this.disableService = false;
    }

    messageClicked(e, id, name, email, phone) {
        this.messageForm.patchValue({
            message: this.translate.instant('booking.Hello') + ' ' + name + '! ',
        });
        console.log(id + ' + ' + name);
        this.messageId = id;
        this.messageUserName = name;
        this.messageUserEmail = email;
        this.messageUserPhone = phone;
        this.messageShow = true;
    }

    closeMessage() {
        this.messageShow = false;
    }

    submit() {
        console.log('promocode', this.promocode.value);
        this.animateLoaderBack();
        // const formData = {...this.form.value};
        // console.log('formData - ', formData);
        this.getUsers(this.pageMode, '&promocode=' + this.promocode.value); // + '&status=' + formData.status + '&service=' + formData.service);
    }

    justclearFilters() {
        this.form.patchValue({
            azza: '0',
            status: '0',
            service: '0',
        });
    }

    clearFilters() {
        this.animateLoaderBack();
        this.form.patchValue({
            azza: '0',
            status: '0',
            service: '0',
        });
        this.getUsers('all', '&azza=0&status=0&service=0');
    }

    messageFormSubmit(transport) {
        if (transport == 'sms') {
            this.sendSmsText = 'Sending...';
        } else {
            this.sendEmailText = 'Sending...';
        }
        const messageFormData = {...this.messageForm.value};
        console.log('messageFormData - ', messageFormData);
        this.UsersService.sendMessage(this.messageId, this.messageUserEmail, this.messageUserPhone, transport, messageFormData.message).subscribe(response => {
            console.log('Response message', response);
            if (transport == 'sms') {
                this.sendSmsText = 'Sent';
            } else {
                this.sendEmailText = 'Sent';
            }
            // this.totalBookings = this.bookings.length;
            // this.gettotalRevenue(response);
            setTimeout(() => {
                if (transport == 'sms') {
                    this.sendSmsText = 'Send SMS';
                } else {
                    this.sendEmailText = 'Send eMail';
                }
            }, 1000);
        });
    }


    toggleFilters() {
        this.showFilterState = this.showFilterState === 'end' ? 'start' : 'end';
    }

    toggleMobUser() {
        console.log('toggleMobUser');
        if (this.childrenActive == false) {
            this.childrenActive = true;
        }
        this.showUserMobState = this.showUserMobState === 'end' ? 'start' : 'end';
    }

    animateLoader() {
        this.hidepreloaderState = 'end';
    }

    animateLoaderBack() {
        this.hidepreloaderState = 'start';
    }
}
