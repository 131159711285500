import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UsersService} from '../users.service';
import {SubdomainsService} from '../subdomain.service';

@Component({
    selector: 'app-header',
    template: `
        <button class="burger" type="button" (click)="animateLoader()"><img src="assets/img/burger.svg"></button>
        <header [@showMenu]="showMenuState"
                [style.display]="this.showMenuState == 'start' || this.intViewportHeight > 475 ? 'block' : 'none'">
            <!--            [style.width]="showMenuState == 'end' ? '70px' : '256px'">-->
            <div class="mobMenuIn" #mobMenuIn>
                <ul class="menu">
                    <li class="logo logoSmall">
                        <button style="white-space: nowrap;" class="cleanBtn" (click)="this.animateLoader()">
                            <img src="assets/img/cameleon-f.svg">
                            <img class="textLogo" *ngIf="this.showMenuState == 'start'" src="assets/img/cameleon-l.svg">
                        </button>
                    </li>
                    <li *ngIf="SubdomainsService.subdomain != 'agent'">
                        <a class="link {{ dashboard.isActive ? 'active' : ''}}" routerLinkActive #dashboard="routerLinkActive"
                           [routerLinkActiveOptions]="{exact: true}" routerLink="/dashboard">
                            <svg class="iconPage" width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path d="M18.7445 6.34756L16.6545 4.90007C16.6754 4.83671 16.6841 4.77008 16.6802 4.70358C16.6802 4.203 16.6802 3.70055 16.6802 3.19996C16.6802 2.69938 16.6802 2.19691 16.6802 1.69633C16.6802 1.41563 16.5956 1.33048 16.3154 1.33048C15.6504 1.33048 14.9797 1.33048 14.3128 1.33048C14.0373 1.33048 13.9527 1.42405 13.9499 1.70475V3.03061L9.8174 0.16464C9.5115 -0.0468224 9.48774 -0.0496329 9.18754 0.158086L3.9046 3.82218C2.68226 4.66928 1.45739 5.51824 0.229994 6.36908C-0.0179563 6.54031 -0.0607062 6.66662 0.0779938 6.86499C0.477627 7.43824 0.880746 8.00776 1.28735 8.57353C1.36054 8.64602 1.44292 8.70892 1.53245 8.76066C1.6455 8.6961 1.7386 8.64931 1.82315 8.5913L2.26584 8.28347C2.24791 8.34617 2.23927 8.41108 2.24019 8.47621C2.24462 10.831 2.24462 13.1864 2.24019 15.5424C2.24019 15.9167 2.32474 16 2.69619 16H7.21059C7.59059 16 7.65994 15.9317 7.65994 15.5462V9.25469H11.3279V15.577C11.3279 15.9214 11.4058 16.0009 11.7449 16.0009H16.2869C16.6232 16.0009 16.7106 15.9129 16.7106 15.5817C16.7106 13.1907 16.7106 10.8004 16.7106 8.41072C16.7103 8.36201 16.7052 8.31343 16.6954 8.26568L17.177 8.6016C17.462 8.79809 17.5703 8.77657 17.7651 8.50148L18.8528 6.96136C19.0732 6.65633 19.0533 6.56183 18.7445 6.34756Z"
                                          fill="#3F4347"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="19" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span *ngIf="this.showMenuState == 'start'">{{ 'menu.Dashboard' | translate }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="link {{ bookings.isActive ? 'active' : ''}}" routerLinkActive #bookings="routerLinkActive"
                           routerLink="/bookings">
                            <svg class="iconPage" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.625 2.625H6.125C5.42881 2.625 4.76113 2.90156 4.26884 3.39384C3.77656 3.88613 3.5 4.55381 3.5 5.25V15.75C3.5 16.4462 3.77656 17.1139 4.26884 17.6062C4.76113 18.0984 5.42881 18.375 6.125 18.375H16.625C16.8571 18.375 17.0796 18.2828 17.2437 18.1187C17.4078 17.9546 17.5 17.7321 17.5 17.5V3.5C17.5 3.26794 17.4078 3.04538 17.2437 2.88128C17.0796 2.71719 16.8571 2.625 16.625 2.625ZM6.125 16.625C5.89294 16.625 5.67038 16.5328 5.50628 16.3687C5.34219 16.2046 5.25 15.9821 5.25 15.75C5.25 15.5179 5.34219 15.2954 5.50628 15.1313C5.67038 14.9672 5.89294 14.875 6.125 14.875H15.75V16.625H6.125Z"
                                      fill="#3F4347"/>
                            </svg>
                            <span *ngIf="this.showMenuState == 'start'">{{ 'menu.Bookings' | translate }}</span>
                        </a>
                    </li>
                    <li *ngIf="SubdomainsService.subdomain != 'agent'">
                        <a class="link {{ users.isActive ? 'active' : ''}}" routerLinkActive #users="routerLinkActive" routerLink="/users">
                            <svg class="iconPage" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.875 9.625C8.56723 9.625 9.24392 9.41973 9.8195 9.03514C10.3951 8.65056 10.8437 8.10394 11.1086 7.46439C11.3735 6.82485 11.4428 6.12112 11.3077 5.44219C11.1727 4.76325 10.8394 4.13961 10.3499 3.65013C9.86039 3.16064 9.23675 2.8273 8.55782 2.69225C7.87888 2.5572 7.17515 2.62652 6.53561 2.89142C5.89607 3.15633 5.34944 3.60493 4.96486 4.18051C4.58027 4.75608 4.375 5.43277 4.375 6.125C4.375 7.05326 4.74375 7.9435 5.40013 8.59988C6.0565 9.25625 6.94674 9.625 7.875 9.625Z"
                                      fill="#3F4347"/>
                                <path d="M14.875 11.375C15.3942 11.375 15.9017 11.221 16.3334 10.9326C16.7651 10.6442 17.1015 10.2342 17.3002 9.75454C17.4989 9.27489 17.5508 8.74709 17.4496 8.23789C17.3483 7.72869 17.0983 7.26096 16.7312 6.89385C16.364 6.52673 15.8963 6.27673 15.3871 6.17544C14.8779 6.07415 14.3501 6.12614 13.8705 6.32482C13.3908 6.5235 12.9808 6.85995 12.6924 7.29163C12.404 7.72331 12.25 8.23082 12.25 8.75C12.25 9.44619 12.5266 10.1139 13.0188 10.6062C13.5111 11.0984 14.1788 11.375 14.875 11.375Z"
                                      fill="#3F4347"/>
                                <path d="M18.375 17.5C18.6071 17.5 18.8296 17.4078 18.9937 17.2438C19.1578 17.0797 19.25 16.8571 19.25 16.625C19.2493 15.8073 19.0194 15.006 18.5864 14.3123C18.1534 13.6186 17.5346 13.0601 16.8003 12.7002C16.066 12.3403 15.2455 12.1935 14.4319 12.2763C13.6183 12.3591 12.8442 12.6683 12.1975 13.1688C11.3404 12.3151 10.2498 11.7343 9.0631 11.4997C7.87639 11.265 6.64678 11.387 5.52932 11.8503C4.41187 12.3135 3.45661 13.0973 2.78402 14.1028C2.11144 15.1082 1.75164 16.2904 1.75 17.5C1.75 17.7321 1.84219 17.9547 2.00628 18.1188C2.17038 18.2828 2.39294 18.375 2.625 18.375H13.125C13.3571 18.375 13.5796 18.2828 13.7437 18.1188C13.9078 17.9547 14 17.7321 14 17.5"
                                      fill="#3F4347"/>
                            </svg>
                            <span *ngIf="this.showMenuState == 'start'">{{ 'menu.Users' | translate }}</span>
                        </a>
                    </li>
                    <li *ngIf="SubdomainsService.subdomain == 'agent'">
                        <a class="link {{ users.isActive ? 'active' : ''}}" routerLinkActive #users="routerLinkActive"
                           routerLink="/users/{{this.userId}}">
                            <svg class="iconPage" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.875 9.625C8.56723 9.625 9.24392 9.41973 9.8195 9.03514C10.3951 8.65056 10.8437 8.10394 11.1086 7.46439C11.3735 6.82485 11.4428 6.12112 11.3077 5.44219C11.1727 4.76325 10.8394 4.13961 10.3499 3.65013C9.86039 3.16064 9.23675 2.8273 8.55782 2.69225C7.87888 2.5572 7.17515 2.62652 6.53561 2.89142C5.89607 3.15633 5.34944 3.60493 4.96486 4.18051C4.58027 4.75608 4.375 5.43277 4.375 6.125C4.375 7.05326 4.74375 7.9435 5.40013 8.59988C6.0565 9.25625 6.94674 9.625 7.875 9.625Z"
                                      fill="#3F4347"/>
                                <path d="M14.875 11.375C15.3942 11.375 15.9017 11.221 16.3334 10.9326C16.7651 10.6442 17.1015 10.2342 17.3002 9.75454C17.4989 9.27489 17.5508 8.74709 17.4496 8.23789C17.3483 7.72869 17.0983 7.26096 16.7312 6.89385C16.364 6.52673 15.8963 6.27673 15.3871 6.17544C14.8779 6.07415 14.3501 6.12614 13.8705 6.32482C13.3908 6.5235 12.9808 6.85995 12.6924 7.29163C12.404 7.72331 12.25 8.23082 12.25 8.75C12.25 9.44619 12.5266 10.1139 13.0188 10.6062C13.5111 11.0984 14.1788 11.375 14.875 11.375Z"
                                      fill="#3F4347"/>
                                <path d="M18.375 17.5C18.6071 17.5 18.8296 17.4078 18.9937 17.2438C19.1578 17.0797 19.25 16.8571 19.25 16.625C19.2493 15.8073 19.0194 15.006 18.5864 14.3123C18.1534 13.6186 17.5346 13.0601 16.8003 12.7002C16.066 12.3403 15.2455 12.1935 14.4319 12.2763C13.6183 12.3591 12.8442 12.6683 12.1975 13.1688C11.3404 12.3151 10.2498 11.7343 9.0631 11.4997C7.87639 11.265 6.64678 11.387 5.52932 11.8503C4.41187 12.3135 3.45661 13.0973 2.78402 14.1028C2.11144 15.1082 1.75164 16.2904 1.75 17.5C1.75 17.7321 1.84219 17.9547 2.00628 18.1188C2.17038 18.2828 2.39294 18.375 2.625 18.375H13.125C13.3571 18.375 13.5796 18.2828 13.7437 18.1188C13.9078 17.9547 14 17.7321 14 17.5"
                                      fill="#3F4347"/>
                            </svg>
                            <span *ngIf="this.showMenuState == 'start'">{{ 'menu.Users' | translate }}</span>
                        </a>
                    </li>
                    <li *ngIf="SubdomainsService.subdomain != 'agent'">
                        <a class="link {{ salary.isActive ? 'active' : ''}}" routerLinkActive #salary="routerLinkActive"
                           routerLink="/salary">
                            <svg class="iconPage" width="21" height="21" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.8995 4.10047C21.2553 1.45622 17.7395 0 14 0C10.2605 0 6.74472 1.45622 4.10047 4.10047C1.45622 6.74472 0 10.2605 0 14C0 17.7395 1.45627 21.2552 4.10047 23.8995C6.74466 26.5438 10.2605 28 14 28C17.7395 28 21.2553 26.5438 23.8995 23.8995C26.5438 21.2553 28 17.7395 28 14C28 10.2605 26.5437 6.74477 23.8995 4.10047V4.10047ZM14 13.125C16.1711 13.125 17.9375 14.8914 17.9375 17.0625C17.9375 18.8605 16.7258 20.3798 15.0764 20.8492C14.9579 20.883 14.875 20.9896 14.875 21.1128V21.8503C14.875 22.3215 14.5129 22.7269 14.0423 22.749C13.5398 22.7726 13.125 22.3723 13.125 21.875V21.1126C13.125 20.9897 13.0426 20.8831 12.9245 20.8494C11.2821 20.3824 10.0735 18.8745 10.0626 17.0869C10.0597 16.6098 10.4348 16.2016 10.9118 16.1879C11.4068 16.1737 11.8125 16.5707 11.8125 17.0625C11.8125 18.3204 12.8798 19.3338 14.1564 19.2445C15.2339 19.1692 16.1068 18.2963 16.182 17.2187C16.2712 15.9422 15.2579 14.875 14 14.875C11.8289 14.875 10.0625 13.1086 10.0625 10.9375C10.0625 9.13954 11.2742 7.62021 12.9236 7.15077C13.0421 7.11703 13.125 7.01045 13.125 6.88723V6.14972C13.125 5.67853 13.4871 5.27313 13.9577 5.25098C14.4603 5.22736 14.875 5.62773 14.875 6.125V6.8874C14.875 7.01028 14.9574 7.11692 15.0755 7.15056C16.7179 7.61759 17.9265 9.12548 17.9374 10.9131C17.9403 11.3902 17.5652 11.7984 17.0882 11.8121C16.5932 11.8263 16.1875 11.4293 16.1875 10.9375C16.1875 9.67958 15.1202 8.66622 13.8436 8.75547C12.7661 8.83077 11.8932 9.7037 11.818 10.7813C11.7288 12.0578 12.7421 13.125 14 13.125V13.125Z"
                                      fill="#3F4347"/>
                            </svg>
                            <span *ngIf="this.showMenuState == 'start'">{{ 'menu.Salary' | translate }}</span>
                        </a>
                    </li>

                    <li *ngIf="SubdomainsService.subdomain != 'agent'">
                        <a class="link {{ sales.isActive ? 'active' : ''}}" routerLinkActive #sales="routerLinkActive"
                           routerLink="/sales">
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.4584 3.54163H3.54175C2.97816 3.54163 2.43766 3.76551 2.03915 4.16402C1.64063 4.56254 1.41675 5.10304 1.41675 5.66663V11.3333C1.41675 11.8969 1.64063 12.4374 2.03915 12.8359C2.43766 13.2344 2.97816 13.4583 3.54175 13.4583H13.4584C14.022 13.4583 14.5625 13.2344 14.961 12.8359C15.3595 12.4374 15.5834 11.8969 15.5834 11.3333V5.66663C15.5834 5.10304 15.3595 4.56254 14.961 4.16402C14.5625 3.76551 14.022 3.54163 13.4584 3.54163ZM7.79175 10.625H4.95842C4.77055 10.625 4.59039 10.5503 4.45755 10.4175C4.32471 10.2847 4.25008 10.1045 4.25008 9.91663C4.25008 9.72876 4.32471 9.5486 4.45755 9.41576C4.59039 9.28292 4.77055 9.20829 4.95842 9.20829H7.79175C7.97961 9.20829 8.15978 9.28292 8.29262 9.41576C8.42545 9.5486 8.50008 9.72876 8.50008 9.91663C8.50008 10.1045 8.42545 10.2847 8.29262 10.4175C8.15978 10.5503 7.97961 10.625 7.79175 10.625ZM12.0417 10.625H10.6251C10.4372 10.625 10.2571 10.5503 10.1242 10.4175C9.99138 10.2847 9.91675 10.1045 9.91675 9.91663C9.91675 9.72876 9.99138 9.5486 10.1242 9.41576C10.2571 9.28292 10.4372 9.20829 10.6251 9.20829H12.0417C12.2296 9.20829 12.4098 9.28292 12.5426 9.41576C12.6755 9.5486 12.7501 9.72876 12.7501 9.91663C12.7501 10.1045 12.6755 10.2847 12.5426 10.4175C12.4098 10.5503 12.2296 10.625 12.0417 10.625ZM14.1667 6.37496H2.83341V5.66663C2.83341 5.47876 2.90804 5.2986 3.04088 5.16576C3.17372 5.03292 3.35389 4.95829 3.54175 4.95829H13.4584C13.6463 4.95829 13.8264 5.03292 13.9593 5.16576C14.0921 5.2986 14.1667 5.47876 14.1667 5.66663V6.37496Z"
                                      fill="#3F4347"/>
                            </svg>
                            <span *ngIf="this.showMenuState == 'start'">{{ 'menu.Sales' | translate }}</span>
                        </a>
                    </li>

                    <li style="display: none;">
                        <a class="link {{ invoices.isActive ? 'active' : ''}}" routerLinkActive #invoices="routerLinkActive"
                           routerLink="/invoices">
                            <svg class="iconPage" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.4584 3.54163H3.54175C2.97816 3.54163 2.43766 3.76551 2.03915 4.16402C1.64063 4.56254 1.41675 5.10304 1.41675 5.66663V11.3333C1.41675 11.8969 1.64063 12.4374 2.03915 12.8359C2.43766 13.2344 2.97816 13.4583 3.54175 13.4583H13.4584C14.022 13.4583 14.5625 13.2344 14.961 12.8359C15.3595 12.4374 15.5834 11.8969 15.5834 11.3333V5.66663C15.5834 5.10304 15.3595 4.56254 14.961 4.16402C14.5625 3.76551 14.022 3.54163 13.4584 3.54163ZM7.79175 10.625H4.95842C4.77055 10.625 4.59039 10.5503 4.45755 10.4175C4.32471 10.2847 4.25008 10.1045 4.25008 9.91663C4.25008 9.72876 4.32471 9.5486 4.45755 9.41576C4.59039 9.28292 4.77055 9.20829 4.95842 9.20829H7.79175C7.97961 9.20829 8.15978 9.28292 8.29262 9.41576C8.42545 9.5486 8.50008 9.72876 8.50008 9.91663C8.50008 10.1045 8.42545 10.2847 8.29262 10.4175C8.15978 10.5503 7.97961 10.625 7.79175 10.625ZM12.0417 10.625H10.6251C10.4372 10.625 10.2571 10.5503 10.1242 10.4175C9.99138 10.2847 9.91675 10.1045 9.91675 9.91663C9.91675 9.72876 9.99138 9.5486 10.1242 9.41576C10.2571 9.28292 10.4372 9.20829 10.6251 9.20829H12.0417C12.2296 9.20829 12.4098 9.28292 12.5426 9.41576C12.6755 9.5486 12.7501 9.72876 12.7501 9.91663C12.7501 10.1045 12.6755 10.2847 12.5426 10.4175C12.4098 10.5503 12.2296 10.625 12.0417 10.625ZM14.1667 6.37496H2.83341V5.66663C2.83341 5.47876 2.90804 5.2986 3.04088 5.16576C3.17372 5.03292 3.35389 4.95829 3.54175 4.95829H13.4584C13.6463 4.95829 13.8264 5.03292 13.9593 5.16576C14.0921 5.2986 14.1667 5.47876 14.1667 5.66663V6.37496Z"
                                      fill="#3F4347"/>
                            </svg>
                            <span *ngIf="this.showMenuState == 'start'">{{ 'menu.Invoices' | translate }}</span>
                        </a>
                    </li>
                    <li *ngIf="SubdomainsService.subdomain != 'agent'">
                        <a class="link {{ settings.isActive ? 'active' : ''}}" routerLinkActive #settings="routerLinkActive"
                           routerLink="/settings">
                            <svg class="iconPage" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99992 8.33333C4.08325 8.33333 3.33325 9.08333 3.33325 9.99999C3.33325 10.9167 4.08325 11.6667 4.99992 11.6667C5.91658 11.6667 6.66658 10.9167 6.66658 9.99999C6.66658 9.08333 5.91658 8.33333 4.99992 8.33333ZM14.9999 8.33333C14.0833 8.33333 13.3333 9.08333 13.3333 9.99999C13.3333 10.9167 14.0833 11.6667 14.9999 11.6667C15.9166 11.6667 16.6666 10.9167 16.6666 9.99999C16.6666 9.08333 15.9166 8.33333 14.9999 8.33333ZM9.99992 8.33333C9.08325 8.33333 8.33325 9.08333 8.33325 9.99999C8.33325 10.9167 9.08325 11.6667 9.99992 11.6667C10.9166 11.6667 11.6666 10.9167 11.6666 9.99999C11.6666 9.08333 10.9166 8.33333 9.99992 8.33333Z"
                                      fill="#777C80"/>
                            </svg>
                            <span *ngIf="this.showMenuState == 'start'">{{ 'menu.Settings' | translate }}</span>
                        </a>
                    </li>
                    <li>
                        <button class="link" type="button" (click)="logout()">
                            <svg class="iconPage" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.82 11.42L18 7.41995C17.8471 7.20436 17.615 7.05809 17.3545 7.01312C17.0941 6.96815 16.8264 7.02813 16.61 7.17995C16.5018 7.25574 16.4098 7.35219 16.3391 7.46376C16.2684 7.57532 16.2206 7.69977 16.1982 7.82994C16.1759 7.9601 16.1796 8.09339 16.2091 8.22212C16.2386 8.35085 16.2933 8.47247 16.37 8.57995L18.09 11H10C9.73478 11 9.48043 11.1053 9.29289 11.2928C9.10536 11.4804 9 11.7347 9 12C9 12.2652 9.10536 12.5195 9.29289 12.7071C9.48043 12.8946 9.73478 13 10 13H18L16.2 15.4C16.1212 15.505 16.0639 15.6246 16.0313 15.7518C15.9987 15.879 15.9915 16.0114 16.01 16.1414C16.0286 16.2714 16.0726 16.3964 16.1395 16.5094C16.2064 16.6224 16.2949 16.7212 16.4 16.7999C16.5731 16.9298 16.7836 17 17 17C17.1552 17 17.3084 16.9638 17.4472 16.8944C17.5861 16.8249 17.7069 16.7241 17.8 16.6L20.8 12.6C20.9281 12.4308 20.999 12.2253 21.0026 12.0132C21.0062 11.8011 20.9423 11.5933 20.82 11.42Z"
                                      fill="#94959C"/>
                                <path d="M7 6C7.26522 6 7.51957 5.89464 7.70711 5.70711C7.89464 5.51957 8 5.26522 8 5C8 4.73478 7.89464 4.48043 7.70711 4.29289C7.51957 4.10536 7.26522 4 7 4H5C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H7C7.26522 20 7.51957 19.8946 7.70711 19.7071C7.89464 19.5196 8 19.2652 8 19C8 18.7348 7.89464 18.4804 7.70711 18.2929C7.51957 18.1054 7.26522 18 7 18H6V6H7Z"
                                      fill="#94959C"/>
                            </svg>
                            <span *ngIf="this.showMenuState == 'start'">{{ 'menu.Logout' | translate }}</span>
                        </button>
                    </li>
                </ul>
                <!--                <button class="toggleMenu link" type="button" (click)="animateLoader()">-->
                <!--                    <img class="iconPage" [src]="this.showMenuState === 'start' ? 'assets/img/toggle.svg' : 'assets/img/toggle-active.svg'">-->
                <!--                    <span style="font-size: 12px; white-space: nowrap;" [@showMenuHeader]="showMenuHeaderState">Toggle sidebar</span>-->
                <!--                </button>-->
            </div>
        </header>
    `,
    styles: [`

        .link {
            text-decoration: blink;
            display: flex !important;
            align-items: center;
            height: 50px;
        }

        .active svg path {
            fill: #0a75ba;
        }

        .link:hover svg path {
            fill: #0a75ba;
        }

        .iconPage path {
            fill: #3f4347;
        }

        @media screen and (max-width: 475px) {
            header {
                z-index: 5 !important;
                width: 100% !important;
                /*padding-right: calc(100% - 256px);*/
                background: transparent !important;
                box-shadow: none !important;
            }

            .mobMenuIn {
                background: #23242a;
                height: 100%;
                box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.15);
            }

            ul {
                padding: 5px 0 !important;
            }
        }

        @media screen and (min-width: 476px) {
            .toggleMenu {
                /*display: none !important;*/
            }

            .burger {
                display: none;
            }
        }

        .burger {
            position: fixed;
            z-index: 9;
            padding: 0px 15px 0 !important;
            background: #24272A;
            border: none;
            top: 0;
            height: 62px;
        }

        header {
            color: #fff;
            background: #24272A;
            box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.15);
            height: 100vh;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 8;
        }

        .menu a.link {
            display: block;
            padding: 15px 23px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            margin: 0;
            padding: 0;
        }

        .logoSmall {
            margin: 12px 0;
            display: block;
            padding: 0 20px;
            width: 100%;
        }

        .textLogo {
            margin-left: 10px;
        }

        li.logo {
            display: flex;
            align-items: center;
        }

        span.activeLogo {
            font-size: 18px;
            font-weight: bold;
        }

        .active, .link:hover {
            background: #141618;
            border-left: 3px solid #0A76BA;
            padding: 15px 20px 15px 20px !important;
        }

        button.toggleMenu {
            background: transparent;
            border: none;
            padding: 40px 27px 10px;
            outline: none;
        }

        li.lastMenuPoint {
            margin-top: 22px;
            padding-top: 22px;
            border-top: 1px solid #32363F;
        }

        button.userIcon {
            background: transparent;
            border: none;
            padding: 24px 10px;
        }

        button.userIcon img {
            width: 50px;
        }

        .link span {
            color: #fff;
            text-decoration: none;
            padding: 0 14px;
            overflow: hidden;
            white-space: nowrap;
        }

        .linkUserName span {
            color: #fff;
            text-decoration: none;
            padding: 0 7px;
            overflow: hidden;
        }

        .link img {
            width: 20px;
        }

        .linkUserName {
            text-decoration: blink;
            display: flex !important;
            align-items: center;
        }

        .linkUserName span > span {
            display: block;
            text-align: left;
        }

        .currentUserImg, .currentUserImg div {
            width: 50px;
            height: 50px;
            background-size: cover !important;
            border-radius: 25px;
        }

        button.link {
            display: block;
            padding: 15px 20px;
            background: transparent;
            width: 100%;
            border: none;
        }

        ul svg {
            width: 21px;
            height: 21px;
            min-width: 21px;
            min-height: 21px;
        }

        a.active span, header a:hover span {
            color: #fff !important;
            font-weight: 900;
        }
    `],
    animations: [
        trigger('showMenu', [
            state('start', style({
                width: '256px',
            })),
            state('end', style({
                width: '70px',
            })),
            transition('start => end', animate(100)),
            transition('end => start', animate(100))
        ]),
        // trigger('showMenuHeader', [
        //     state('start', style({
        //         width: '0',
        //         opacity: 0,
        //         display: 'none'
        //     })),
        //     state('end', style({
        //         width: 'auto',
        //         opacity: 1,
        //         display: 'inline-block'
        //     })),
        //     transition('start => end', animate(100)),
        //     transition('end => start', animate(100))
        // ])
    ],
})
export class HeaderComponent implements OnInit {
    constructor(private UsersService: UsersService, private router: Router, private http: HttpClient, public SubdomainsService: SubdomainsService) {
    }

    intViewportHeight = window.innerWidth;
    currentUser: any;
    showMenuState = '';
    showMenuHeaderState = '';
    canClose = false;
    userId = localStorage.getItem('user_id');

    @ViewChild('mobMenuIn') mobMenuIn: ElementRef;

    @HostListener('document:click', ['$event'])
    onClick(event: Event) {
        console.log('click');
        if (this.canClose) {
            if (!this.mobMenuIn.nativeElement.contains(event.target)) {
                this.animateLoader();
            }
        }
    }

    ngOnInit() {
        this.UsersService.getCurrentUserInfo().subscribe(response => {
            console.log('Response getCurrentUserInfo', response);
            this.currentUser = response.user_info;
            console.log('this.currentUser', this.currentUser);
            // if (response.status === 1) {
            //   this.router.navigate(['/bookings']);
            // }
        });
        // if (this.intViewportHeight > 415) {
        this.showMenuState = 'end';
        this.showMenuHeaderState = 'end';
        // } else {
        //     this.showMenuState = 'start';
        //     this.showMenuHeaderState = 'start';
        // }
    }

    logout() {
        localStorage.removeItem('access_key');
        localStorage.removeItem('auth_key');
        localStorage.removeItem('user_id');
        setTimeout(() => {
            this.SubdomainsService.gotoHomePage();
        }, 50);
    }

    animateLoader() {
        // setTimeout(() => {
        //     this.canClose = this.canClose === false ? true : false;
        // }, 1000);
        this.showMenuState = this.showMenuState === 'end' ? 'start' : 'end';
        // setTimeout(() => {
        //     this.showMenuHeaderState = this.showMenuHeaderState === 'end' ? 'start' : 'end';
        // }, 100);
    }
}
