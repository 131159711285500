<app-header></app-header>
<app-topheader></app-topheader>
<div class="main">
    <div class="loader" [@hidepreloader]="hidepreloaderState">
        <img class="loaderImg" src="assets/img/loader.gif">
    </div>
    <div class="salesTable">
        <div class="table">
            <div class="tableHead">
                <div class="year">Month \ Year</div>
                <div>Jan</div>
                <div>Feb</div>
                <div>Mar</div>
                <div>Apr</div>
                <div>May</div>
                <div>Jun</div>
                <div>Jul</div>
                <div>Aug</div>
                <div>Sep</div>
                <div>Oct</div>
                <div>Nov</div>
                <div>Dec</div>
                <div>Total</div>
            </div>
            <div class="tableRow" *ngFor="let year of sales;">
                <div class="year">
                    {{year.year}}
                </div>
                <div class="month">
                    <div class="paid">{{year.month['01']?.totalPaid ? year.month['01']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['01']?.totalUnPaid ? year.month['01']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['02']?.totalPaid ? year.month['02']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['02']?.totalUnPaid ? year.month['02']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['03']?.totalPaid ? year.month['03']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['03']?.totalUnPaid ? year.month['03']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['04']?.totalPaid ? year.month['04']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['04']?.totalUnPaid ? year.month['04']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['05']?.totalPaid ? year.month['05']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['05']?.totalUnPaid ? year.month['05']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['06']?.totalPaid ? year.month['06']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['06']?.totalUnPaid ? year.month['06']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['07']?.totalPaid ? year.month['07']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['07']?.totalUnPaid ? year.month['07']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['08']?.totalPaid ? year.month['08']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['08']?.totalUnPaid ? year.month['08']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['09']?.totalPaid ? year.month['09']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['09']?.totalUnPaid ? year.month['09']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['10']?.totalPaid ? year.month['10']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['10']?.totalUnPaid ? year.month['10']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['11']?.totalPaid ? year.month['11']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['11']?.totalUnPaid ? year.month['11']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.month['12']?.totalPaid ? year.month['12']?.totalPaid + '€' : '0€'}}</div>
                    <div class="unPaid">{{year.month['12']?.totalUnPaid ? year.month['12']?.totalUnPaid + '€' : '0€'}}</div>
                </div>
                <div class="month">
                    <div class="paid">{{year.totalPaidYear + '€'}}</div>
                    <div class="unPaid">{{year.totalUnPaidYear + '€'}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
