<app-header></app-header>
<app-topheader></app-topheader>
<div class="main">
    <div class="loader" [@hidepreloader]="hidepreloaderState">
        <img class="loaderImg" src="assets/img/loader.gif">
    </div>
    <div class="row">
        <div class="col-md-6 block pR">
            <div class="box" *ngIf="SubdomainsService.subdomain == 'pro'">
                <h4>{{ 'settings.Company settings' | translate }}</h4>
                <p>{{ 'settings.This info will show on invoice (if you\'ll stay this fields empty your invoices will be send from 1EYEONU)' | translate }}</p>
                <form class="loginForm fullForm" [formGroup]="CompanySettings" (scroll)="onScroll()"
                      (ngSubmit)="submitCompanySettings()">
                    <div class="formScroll">
                        <div class="option">
                            <div class="key">{{ 'settings.Logo' | translate }}:</div>
                            <div class="value">
                                <div [style.display]="currentCompany.logo && !uploadModeLogo ? 'block' : 'none'"
                                     class="userDocImgFull">
                                    <img [src]="'https://1eyeonu.com/images/?id=' + currentCompany.logo + '&PHPSESSID=' + session">
                                    <button class="delete-file" type="button" (click)="deleteLogo()">
                                        <img src="assets/img/icon_delete.svg">
                                    </button>
                                </div>
                                <div [style.display]="isUploadedLogo ? 'block' : 'none'" class="userDocImgFull">
                                    <img [src]="'https://1eyeonu.com/images/?id=' + this.uploadedImgLogo">
                                    <button class="delete-file" type="button" (click)="deleteLogo()">
                                        <img src="assets/img/icon_delete.svg">
                                    </button>
                                </div>
                                <div [style.display]="!currentCompany.logo && !isUploadedLogo || uploadModeLogo && !isUploadedLogo ? 'block' : 'none'"
                                     class="userDocImgFull">
                                    <div class="uploadfilecontainer" (click)="fileInputId.click()" appDragDrop
                                         (onFileDropped)="uploadFileLogo($event, '11')">
                                        <input hidden type="file" #fileInputId
                                               (change)="uploadFileLogo($event.target.files, '11')">
                                    </div>
                                    <div class="files-list" *ngFor="let file of files;let i= index">
                                        <p><span class="progress" [@upload]="uploadState"></span><span
                                                class="fileName">{{ file }}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="option">
                            <div class="key">{{ 'settings.Company name' | translate }}:</div>
                            <div class="value">
                                <input class="input" formControlName="companyName"/>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">{{ 'settings.Company address' | translate }}</div>
                            <div class="value">
                                <input class="address" ngx-google-places-autocomplete formControlName="companyAddress"
                                       #placeAddress #placeAddress="ngx-places" placeholder=""
                                       (onAddressChange)="handleAddressChangeAddress($event)"/>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">Activites privees de securite / Surveillance ou gardiennage:</div>
                            <div class="value">
                                <input class="input" formControlName="licence1"/>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">Activites privees de securite / Protection physique des personnes:</div>
                            <div class="value">
                                <input class="input" formControlName="licence2"/>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">L'autorisation d'exercice ne confere aucune prerogative de puissance
                                publique a l'entreprise ou aux personnes qui en beneficient:
                            </div>
                            <div class="value">
                                <input class="input" formControlName="licence3"/>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">Licence chauffeur:</div>
                            <div class="value">
                                <input class="input" formControlName="licence4"/>
                            </div>
                        </div>

                    </div>
                    <div class="saveWrap">
                        <button class="btn" type="submit"
                                [disabled]="CompanySettings.invalid">{{ 'booking.' + saveCompanySettingsSaved | translate }}</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-6 block pL">
            <div class="box" *ngIf="SubdomainsService.subdomain == 'pro'">
                <h4>{{ 'settings.Email settings' | translate }}</h4>
                <p>{{ 'settings.This info will use to send invoice (if you\'ll stay this fields empty your invoices will be send from 1EYEONU)' | translate }}</p>
                <form class="loginForm fullForm" [formGroup]="emailSettings" (ngSubmit)="submitEmailSettings()">
                    <div class="formScroll">
                        <div class="option">
                            <div class="key">{{ 'settings.Your email service' | translate }}:</div>
                            <div class="value">
                                <mat-select formControlName="emailService" (valueChange)="emailServiceSelected()">
                                    <mat-option value="1">{{ 'settings.Other' | translate }}</mat-option>
                                    <mat-option value="2">Gmail</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="option" *ngIf="emailSettings.get('emailService').value == 1">
                            <div class="key">{{ 'settings.Email host' | translate }}:</div>
                            <div class="value">
                                <input class="input" formControlName="host"/>
                            </div>
                        </div>
                        <div class="option" *ngIf="emailSettings.get('emailService').value == 1">
                            <div class="key">{{ 'settings.Email encryption' | translate }}:</div>
                            <div class="value">
                                <mat-select formControlName="encryption">
                                    <mat-option value="ssl">SSL</mat-option>
                                    <mat-option value="tls">TLS</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="option" *ngIf="emailSettings.get('emailService').value == 1">
                            <div class="key">{{ 'settings.Email port' | translate }}:</div>
                            <div class="value">
                                <input class="input" formControlName="port"/>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">{{ 'settings.Email login' | translate }}:</div>
                            <div class="value">
                                <input class="input" formControlName="login"/>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">{{ 'settings.Email password' | translate }}:</div>
                            <div class="value">
                                <input type="password" class="input" formControlName="password"/>
                            </div>
                        </div>
                        <div class="saveWrap">
                            <button class="btn" type="submit"
                                    [disabled]="emailSettings.invalid">{{ 'booking.' + saveEmailSettingsSaved | translate }}</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 block pR">
            <div class="box" *ngIf="SubdomainsService.subdomain == 'admin'">
                <h2>Cars</h2>
                <div style="position: relative;" class="car" *ngFor="let car of cars; let i = index">
                    <p>{{ car.name }}</p>
                    <mat-slide-toggle
                            [checked]="car.active == 1 ? true : false"
                            (change)="getCars(car.id, car.active)"></mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>
</div>
