import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [
    trigger( 'hidepreloader', [
      state( 'start', style({
        display: 'flex',
        opacity: 1
      })),
      state( 'end', style({
        display: 'none',
        opacity: 0
      })),
      transition('start => end', animate(500))
    ])
  ],
})

export class DashboardComponent implements OnInit {
  hidepreloaderState = 'start';

  ngOnInit() {
    setTimeout (() => {
      this.animateLoader();
    }, 500);

  }

  animateLoader() {
    this.hidepreloaderState = 'end';
  }
}
