import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UsersService} from '../users.service';
import {SubdomainsService} from '../subdomain.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-dashboard',
    templateUrl: './salary.component.html',
    styleUrls: ['./salary.component.css'],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(500))
        ])
    ],
})

export class SalaryComponent implements OnInit {

    hidepreloaderState = 'start';
    salary: any;
    form: FormGroup;

    constructor(private router: Router, private route: ActivatedRoute, public SubdomainsService: SubdomainsService, private UsersService: UsersService) {

        this.form = new FormGroup({
            month: new FormControl(new Date().getMonth() + 1),
            year: new FormControl('2022'),
        });
    }

    ngOnInit() {
        this.SubdomainsService.isAdmin();
        const today = new Date();
        const month = today.getMonth();
        const year = today.getFullYear();
        console.log(month, year);

        this.form.patchValue({
            month: '' + (month + 1),
            year: '' + year,
        });

        this.getAgentsSalary((month + 1), year);

        setTimeout(() => {
            this.animateLoader();
        }, 500);

    }

    animateLoader() {
        this.hidepreloaderState = 'end';
    }

    getAgentsSalary(month, year) {
        this.UsersService.getAgentsSalary(month, year).subscribe(response => {
            console.log('response salary data', response);
            // this.salary = response;
            const localSalary = response;
            // Object.entries(this.salary.users).forEach(currentValue => {
            //     // @ts-ignore
            //     currentValue[1].sort(function(a: any, b: any) {
            //         if (a.booking_id > b.booking_id) {
            //             return 1;
            //         }
            //         if (a.booking_id < b.booking_id) {
            //             return -1;
            //         }
            //         // a должно быть равным b
            //         return 0;
            //     });
            // });
            if (Object.values(localSalary)[0]) {
                this.salary = Object.values(Object.values(localSalary)[0]);
            } else {
                this.salary = [];
            }
            console.log('response salary data +++', this.salary);
        });
    }

    submit() {
        setTimeout(() => {
            const formData = {...this.form.value};
            console.log('Form submitted: ', formData);
            this.getAgentsSalary(formData.month, formData.year);
        }, 100);
    }

    // OLD getAgentsSalary(month, year) {
    //   this.UsersService.getAgentsSalary(month, year).subscribe(response => {
    //     console.log('response salary data', response);
    //     this.salary = response;
    //   });
    // }
    // submit() {
    //   setTimeout (() => {
    //     const formData = {...this.form.value};
    //     console.log('Form submitted: ', formData);
    //     this.getAgentsSalary(formData.month, formData.year);
    //   }, 100);
    // OLD }
}
