<app-header></app-header>
<app-topheader></app-topheader>
<div class="main">
    <div class="loader" [@hidepreloader]="hidepreloaderState">
        <img class="loaderImg" src="assets/img/loader.gif">
    </div>
    <div #boo class="row">
        <div class="col-md-12 salaryHead">
            <form class="dateFilter" [formGroup]="form" (ngSubmit)="submit()">
                <mat-select formControlName="month" (valueChange)="submit()">
                    <mat-option value="1">{{ 'salary.January' | translate }}</mat-option>
                    <mat-option value="2">{{ 'salary.February' | translate }}</mat-option>
                    <mat-option value="3">{{ 'salary.March' | translate }}</mat-option>
                    <mat-option value="4">{{ 'salary.April' | translate }}</mat-option>
                    <mat-option value="5">{{ 'salary.May' | translate }}</mat-option>
                    <mat-option value="6">{{ 'salary.June' | translate }}</mat-option>
                    <mat-option value="7">{{ 'salary.July' | translate }}</mat-option>
                    <mat-option value="8">{{ 'salary.August' | translate }}</mat-option>
                    <mat-option value="9">{{ 'salary.September' | translate }}</mat-option>
                    <mat-option value="10">{{ 'salary.October' | translate }}</mat-option>
                    <mat-option value="11">{{ 'salary.November' | translate }}</mat-option>
                    <mat-option value="12">{{ 'salary.December' | translate }}</mat-option>
                </mat-select>
                <mat-select formControlName="year" (valueChange)="submit()">
                    <mat-option value="2020">2020</mat-option>
                    <mat-option value="2021">2021</mat-option>
                    <mat-option value="2022">2022</mat-option>
                    <mat-option value="2023">2023</mat-option>
                </mat-select>
            </form>
        </div>
    </div>
    <div class="row sal">
        <div class="col-md-12">
            <div class="box salaryBlock head">
                <span>Name</span>
                <span>Revenue</span>
                <span>Work hours</span>
                <span>Rate/h</span>
                <span>Cash</span>
                <span>Balance</span>
                <span></span>
            </div>
            <div class="box salaryBlock" *ngIf="salary?.length > 0">
                <div style="position: relative;" *ngFor="let oneSalary of salary; let i = index;" class="oneSalary">
                    <button class="oneSalaryHead">
                        <span><a target="_blank" href="/users/{{oneSalary[0].id}}">{{oneSalary[0].full_name}}</a></span>
                        <span>{{oneSalary[oneSalary.length - 1].total}} €</span>
                        <span>{{(oneSalary[oneSalary.length - 1].totalWorkH - oneSalary[oneSalary.length - 1].totalWorkH % 60) / 60}}
                            h {{oneSalary[oneSalary.length - 1].totalWorkH % 60}} min</span>
                        <span>{{oneSalary[0].price_rate}} €</span>
                        <span>{{oneSalary[oneSalary.length - 1].totalCash}} €</span>
                        <span>{{oneSalary[oneSalary.length - 1].totalWorkH / 60 * oneSalary[0].price_rate - oneSalary[oneSalary.length - 1].totalCash}}
                            €</span>
                        <span></span>
                    </button>
                    <button type="button" class="triggerB"
                            (click)="oneSalary[0].open = oneSalary[0].open == 1 ? 0 : 1">{{ 'menu.Bookings' | translate }}
                        <img [className]="oneSalary[0].open == 1 ? 'reverse' : ''" src="assets/img/icon_down.svg">
                    </button>
                    <div *ngIf="oneSalary[0].open == 1">
                        <button class="oneHead oneBookingSalary">
                            <div class="bookingN">№</div>
                            <div class="bookingDate">{{ 'bookings.Date' | translate }}</div>
                            <div class="bookingPl">{{ 'filters.Pick up' | translate }}</div>
                            <div class="bookingPl">{{ 'filters.Drop off' | translate }}</div>
                            <div class="bookingPl">{{ 'filters.Client' | translate }}</div>
                            <div class="bookingPr"
                                 *ngIf="SubdomainsService.subdomain != 'agent'">{{ 'bookings.Price' | translate }}</div>
                            <div class="bookingCl"
                                 *ngIf="SubdomainsService.subdomain != 'agent'">{{ 'filters.hours' | translate }}</div>
                        </button>
                        <div style="position: relative;" *ngFor="let sal of oneSalary" class="oneSalaryBooking">
                            <a class="oneBookingSalary" href="/bookings/{{sal.booking_id}}">
                                <div class="oneBookingId">#{{sal.booking_id}}</div>
                                <div class="oneBookingId">{{sal.start_date | date: 'MMM dd, yyyy' : 'UTC'}}</div>
                                <div class="bookingPl">{{sal.pick_up_place }}</div>
                                <div class="bookingPl">{{sal.drop_off_place}}</div>
                                <div class="bookingPl">{{sal.client_name?.length > 10 ? sal.client_name?.substring(0, 10) + '...' : sal.client_name}}</div>
                                <div class="oneBookingId">{{sal.price}} €</div>
                                <div class="bookingCl"
                                     *ngIf="SubdomainsService.subdomain != 'agent'">{{(sal.work_hours - sal.work_hours % 60) / 60}}
                                    h {{sal.work_hours % 60}}m
                                </div>
                                <!--                            <div class="oneBookingId">{{(sal.work_hours - sal.work_hours % 60) / 60}}-->
                                <!--                                h {{sal.work_hours % 60}} min-->
                                <!--                            </div>-->
                            </a>
                        </div>
                    </div>
                    <!--                    <div class="oneUser">-->
                    <!--                        <div style="position: relative;" *ngFor="let user of oneUser" class="oneSalary">-->
                    <!--                            <div class="oneBookingId">{{user.id}}</div>-->
                    <!--                        </div>-->
                    <!--                        <div class="oneBookingId">{{oneUser}}</div>-->
                    <!--                        <div class="oneBookingStartDate">{{oneUser.bookings.length}} {{ 'booking.booking' | translate }}-->
                    <!--                            <span style="padding: 0;" *ngIf="oneUser.bookings.length != 1">s</span></div>-->
                    <!--                        <div class="oneBookingDuration">{{oneUser.totalTime}} {{ 'filters.hour' | translate }}<span-->
                    <!--                                style="padding: 0;" *ngIf="oneUser.totalTime != 1">s</span></div>-->
                    <!--                        <div class="oneBookingDuration">€{{oneUser.total}}</div>-->
                    <!--                        <button type="button" (click)="oneUser.showBookings = !oneUser.showBookings">-->
                    <!--                            <svg *ngIf="!oneUser.showBookings" width="26" height="13" viewBox="0 0 26 13" fill="none"-->
                    <!--                                 xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path d="M13 13L0.00961574 0.250004L25.9904 0.250002L13 13Z" fill="#676767"/>-->
                    <!--                            </svg>-->
                    <!--                            <svg *ngIf="oneUser.showBookings" width="26" height="13" viewBox="0 0 26 13" fill="none"-->
                    <!--                                 xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path d="M13 -2.62268e-06L25.9904 12.75L0.00962099 12.75L13 -2.62268e-06Z"-->
                    <!--                                      fill="#0A76BA"/>-->
                    <!--                            </svg>-->
                    <!--                        </button>-->
                    <!--                    </div>-->
                    <!--                    <div style="position: relative;" *ngFor="let booking of oneUser.bookings" class="oneBooking">-->
                    <!--                        <a [routerLink]="['/bookings', booking.id]" *ngIf="oneUser.showBookings">-->
                    <!--                            <div class="oneUserImg"></div>-->
                    <!--                            <div class="oneBookingId">#{{booking.id}}</div>-->
                    <!--                            <div class="oneBookingStartDate">{{booking.start_date | date: 'MMM dd, yyyy HH:mm' : 'UTC'}}</div>-->
                    <!--                            <div class="oneBookingDuration">{{booking.duration}} {{ 'filters.hour' | translate }}<span-->
                    <!--                                    style="padding: 0;" *ngIf="booking.duration != 1">s</span></div>-->
                    <!--                            <div class="oneBookingDuration">€{{booking.price}}</div>-->
                    <!--                            <button type="button">-->
                    <!--                                <svg width="39" height="39" viewBox="0 0 39 39" fill="none"-->
                    <!--                                     xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                    <rect width="39" height="39" rx="5" fill="#0A76BA" fill-opacity="0.19"/>-->
                    <!--                                    <path d="M27.5719 15.2561L23.218 10.3531C23.1263 10.2515 23.0144 10.1702 22.8894 10.1145C22.7645 10.0588 22.6292 10.0298 22.4924 10.0295H14.6476C14.3217 10.0256 13.9984 10.0859 13.6959 10.207C13.3934 10.3281 13.1177 10.5076 12.8846 10.7353C12.6515 10.9629 12.4655 11.2343 12.3373 11.5338C12.209 11.8333 12.141 12.1552 12.1372 12.481V27.19C12.141 27.5158 12.209 27.8377 12.3373 28.1372C12.4655 28.4368 12.6515 28.7081 12.8846 28.9357C13.1177 29.1634 13.3934 29.3429 13.6959 29.464C13.9984 29.5851 14.3217 29.6454 14.6476 29.6415H25.3165C25.6423 29.6454 25.9657 29.5851 26.2682 29.464C26.5707 29.3429 26.8464 29.1634 27.0795 28.9357C27.3126 28.7081 27.4986 28.4368 27.6268 28.1372C27.755 27.8377 27.823 27.5158 27.8269 27.19V15.9131C27.8262 15.6701 27.7354 15.4359 27.5719 15.2561ZM17.0402 19.8355H19.982C20.2421 19.8355 20.4915 19.9388 20.6754 20.1227C20.8593 20.3066 20.9626 20.556 20.9626 20.8161C20.9626 21.0762 20.8593 21.3256 20.6754 21.5095C20.4915 21.6934 20.2421 21.7967 19.982 21.7967H17.0402C16.7802 21.7967 16.5307 21.6934 16.3468 21.5095C16.1629 21.3256 16.0596 21.0762 16.0596 20.8161C16.0596 20.556 16.1629 20.3066 16.3468 20.1227C16.5307 19.9388 16.7802 19.8355 17.0402 19.8355ZM22.9238 25.7191H17.0402C16.7802 25.7191 16.5307 25.6158 16.3468 25.4319C16.1629 25.248 16.0596 24.9986 16.0596 24.7385C16.0596 24.4785 16.1629 24.229 16.3468 24.0451C16.5307 23.8612 16.7802 23.7579 17.0402 23.7579H22.9238C23.1839 23.7579 23.4333 23.8612 23.6172 24.0451C23.8011 24.229 23.9044 24.4785 23.9044 24.7385C23.9044 24.9986 23.8011 25.248 23.6172 25.4319C23.4333 25.6158 23.1839 25.7191 22.9238 25.7191ZM22.6395 15.9131C22.4373 15.8932 22.2511 15.7947 22.1208 15.6388C21.9906 15.4829 21.9268 15.2821 21.9432 15.0796V11.9907L25.6107 15.9131H22.6395Z"-->
                    <!--                                          fill="#0A76BA"/>-->
                    <!--                                </svg>-->
                    <!--                            </button>-->
                    <!--                        </a>-->
                    <!--                    </div>-->
                </div>
            </div>
            <div class="box salaryBlock" *ngIf="salary.length == 0">
                <p style="text-align: center; margin: 20px;">No data for this period</p>
            </div>
        </div>
    </div>
    <!--  OLD <div class="row sal">-->
    <!--    <div class="col-md-12">-->
    <!--      <div class="box salaryBlock">-->
    <!--        <div style="position: relative;" *ngFor="let oneUser of salary" class="oneSalary">-->
    <!--          <div class="oneUser">-->
    <!--            <div class="oneUserImg" [style.background]="'url(https://1eyeonu.com/images/?id=' + oneUser.image_id + ')'">-->
    <!--            </div>-->
    <!--            <div class="oneBookingId">{{oneUser.full_name}}</div>-->
    <!--            <div class="oneBookingStartDate">{{oneUser.bookings.length}} {{ 'booking.booking' | translate }}<span style="padding: 0;" *ngIf="oneUser.bookings.length != 1">s</span></div>-->
    <!--            <div class="oneBookingDuration">{{oneUser.totalTime}} {{ 'filters.hour' | translate }}<span style="padding: 0;" *ngIf="oneUser.totalTime != 1">s</span></div>-->
    <!--            <div class="oneBookingDuration">€{{oneUser.total}}</div>-->
    <!--            <button type="button" (click)="oneUser.showBookings = !oneUser.showBookings">-->
    <!--              <svg *ngIf="!oneUser.showBookings" width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--                <path d="M13 13L0.00961574 0.250004L25.9904 0.250002L13 13Z" fill="#676767"/>-->
    <!--              </svg>-->
    <!--              <svg *ngIf="oneUser.showBookings" width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--                <path d="M13 -2.62268e-06L25.9904 12.75L0.00962099 12.75L13 -2.62268e-06Z" fill="#0A76BA"/>-->
    <!--              </svg>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--          <div style="position: relative;" *ngFor="let booking of oneUser.bookings" class="oneBooking">-->
    <!--            <a [routerLink]="['/bookings', booking.id]" *ngIf="oneUser.showBookings">-->
    <!--              <div class="oneUserImg"></div>-->
    <!--              <div class="oneBookingId">#{{booking.id}}</div>-->
    <!--              <div class="oneBookingStartDate">{{booking.start_date | date: 'MMM dd, yyyy HH:mm' : 'UTC'}}</div>-->
    <!--              <div class="oneBookingDuration">{{booking.duration}} {{ 'filters.hour' | translate }}<span style="padding: 0;" *ngIf="booking.duration != 1">s</span></div>-->
    <!--              <div class="oneBookingDuration">€{{booking.price}}</div>-->
    <!--              <button type="button">-->
    <!--                <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--                  <rect width="39" height="39" rx="5" fill="#0A76BA" fill-opacity="0.19"/>-->
    <!--                  <path d="M27.5719 15.2561L23.218 10.3531C23.1263 10.2515 23.0144 10.1702 22.8894 10.1145C22.7645 10.0588 22.6292 10.0298 22.4924 10.0295H14.6476C14.3217 10.0256 13.9984 10.0859 13.6959 10.207C13.3934 10.3281 13.1177 10.5076 12.8846 10.7353C12.6515 10.9629 12.4655 11.2343 12.3373 11.5338C12.209 11.8333 12.141 12.1552 12.1372 12.481V27.19C12.141 27.5158 12.209 27.8377 12.3373 28.1372C12.4655 28.4368 12.6515 28.7081 12.8846 28.9357C13.1177 29.1634 13.3934 29.3429 13.6959 29.464C13.9984 29.5851 14.3217 29.6454 14.6476 29.6415H25.3165C25.6423 29.6454 25.9657 29.5851 26.2682 29.464C26.5707 29.3429 26.8464 29.1634 27.0795 28.9357C27.3126 28.7081 27.4986 28.4368 27.6268 28.1372C27.755 27.8377 27.823 27.5158 27.8269 27.19V15.9131C27.8262 15.6701 27.7354 15.4359 27.5719 15.2561ZM17.0402 19.8355H19.982C20.2421 19.8355 20.4915 19.9388 20.6754 20.1227C20.8593 20.3066 20.9626 20.556 20.9626 20.8161C20.9626 21.0762 20.8593 21.3256 20.6754 21.5095C20.4915 21.6934 20.2421 21.7967 19.982 21.7967H17.0402C16.7802 21.7967 16.5307 21.6934 16.3468 21.5095C16.1629 21.3256 16.0596 21.0762 16.0596 20.8161C16.0596 20.556 16.1629 20.3066 16.3468 20.1227C16.5307 19.9388 16.7802 19.8355 17.0402 19.8355ZM22.9238 25.7191H17.0402C16.7802 25.7191 16.5307 25.6158 16.3468 25.4319C16.1629 25.248 16.0596 24.9986 16.0596 24.7385C16.0596 24.4785 16.1629 24.229 16.3468 24.0451C16.5307 23.8612 16.7802 23.7579 17.0402 23.7579H22.9238C23.1839 23.7579 23.4333 23.8612 23.6172 24.0451C23.8011 24.229 23.9044 24.4785 23.9044 24.7385C23.9044 24.9986 23.8011 25.248 23.6172 25.4319C23.4333 25.6158 23.1839 25.7191 22.9238 25.7191ZM22.6395 15.9131C22.4373 15.8932 22.2511 15.7947 22.1208 15.6388C21.9906 15.4829 21.9268 15.2821 21.9432 15.0796V11.9907L25.6107 15.9131H22.6395Z" fill="#0A76BA"/>-->
    <!--                </svg>-->
    <!--              </button>-->
    <!--            </a>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  OLD </div>-->
</div>


<!--bodyguard_id: "36"-->
<!--booking_id: "178,188,187,123,122,121,120"-->
<!--bookings: Array(2)-->
<!--0: {id: "120", start_date: "2020-05-26 20:20:00", duration: "22", bodyguard_type: "5", price: 528}-->
<!--1: {id: "178", start_date: "2020-05-21 13:27:00", duration: "5", bodyguard_type: "2", price: 65}-->
<!--length: 2-->
<!--__proto__: Array(0)-->
<!--image_id: "1067,1067,1067,1067,1067,1067,1067"-->
<!--total: 593-->
<!--user_id: "158"-->
