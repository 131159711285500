import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../users.service';
import {SubdomainsService} from '../subdomain.service';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {BookingsService} from '../bookings.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css'],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(500))
        ]),
        trigger('upload', [
            state('start', style({
                width: 0,
            })),
            state('end', style({
                width: '100%',
            })),
            transition('start => end', animate(1500))
        ])
    ],
})


export class SettingsComponent implements OnInit {

    hidepreloaderState = 'start';
    CompanySettings: FormGroup;
    emailSettings: FormGroup;

    session = localStorage.getItem('access_key');

    currentUserId: '0';
    uploadModeLogo = false;
    isUploadedLogo = false;
    uploadedImgLogo = '';
    uploadState = 'start';
    currentCompany: any = [];
    files: any = [];

    saveEmailSettingsSaved = 'Save';
    saveCompanySettingsSaved = 'Save';

    cars: any = [];

    @ViewChild('place') placesRef: ElementRef;
    @ViewChild('placeAddress') placesAddressRef: ElementRef;

    onScroll() {
        this.placesRef.nativeElement.blur();
        this.placesAddressRef.nativeElement.blur();
    }

    constructor(private router: Router, private route: ActivatedRoute, public SubdomainsService: SubdomainsService, private UsersService: UsersService, public bookingsService: BookingsService) {
        this.CompanySettings = new FormGroup({
            companyName: new FormControl(''),
            companyAddress: new FormControl(''),
            licence1: new FormControl(''),
            licence2: new FormControl(''),
            licence3: new FormControl(''),
            licence4: new FormControl(''),
        });

        this.emailSettings = new FormGroup({
            emailService: new FormControl(''),
            host: new FormControl(''),
            encryption: new FormControl(''),
            port: new FormControl(''),
            login: new FormControl(''),
            password: new FormControl(''),
        });
    }

    ngOnInit() {
        this.SubdomainsService.isAdmin();
        this.SubdomainsService.aythWithAccessKey().subscribe(response => {
            console.log('Response aythWithAccessKey', response);
            if (response.status === 'ERROR') {
                this.SubdomainsService.gotoHomePage();
            }
            if (response.user_id > 0 && response.status === '1') {
                this.SubdomainsService.gotoConfirmCodePage();
            }
        });
        this.getCars(0, 0);
        setTimeout(() => {
            this.animateLoader();
        }, 500);
        this.getPartnerDetails();
    }

    getCars(id, active) {
        console.log('Response getCarsAll');
        if (this.SubdomainsService.subdomain == 'admin') {
            this.bookingsService.getCarsAll(id, active).subscribe(response => {
                console.log('Response getCarsAll', response);
                if (response.status == 'ERROR' && response.error.code == 133 && localStorage.getItem('access_key').length > 0 && localStorage.getItem('auth_key').length > 0) {
                    window.location.reload();
                }
                this.cars = response;
            });
        }
    }

    getPartnerDetails() {
        this.UsersService.getPartnerDetails().subscribe(response => {
            console.log('response ID upload', response);
            this.currentCompany = response;
            this.CompanySettings.patchValue({
                companyName: response.company_name,
                companyAddress: response.company_address,
                licence1: response.licence1,
                licence2: response.licence2,
                licence3: response.licence3,
                licence4: response.licence4,
            });

            this.emailSettings.patchValue({
                emailService: response.emailService,
                host: response.host,
                login: response.login,
                password: response.password,
                encryption: response.encryption,
                port: response.port,
            });

        });
    }

    public handleAddressChangeAddress(address: Address) {
        console.log('address', address.formatted_address);
        this.CompanySettings.patchValue({
            companyAddress: address.formatted_address
        });
    }

    deleteLogo() {
        this.uploadModeLogo = true;
        this.isUploadedLogo = false;
        this.uploadState = 'start';
    }

    uploadFileLogo(event, type) {
        this.files = [];
        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            this.files.push(element.name);
            console.log('file', element, element.name);
            setTimeout(() => {
                this.uploadState = 'end';
            }, 100);
            // upload here
            this.UsersService.uploadLogo(element, type, this.currentUserId).subscribe(response => {
                console.log('response ID upload', response);
                this.isUploadedLogo = true;
                this.uploadedImgLogo = response.user_image;
                this.files = [];
                this.uploadState = 'start';
            });
        }
    }

    emailServiceSelected() {
        setTimeout(() => {
            const formData = {...this.emailSettings.value};
            const service = formData.emailService;
            console.log('service', service);
            if (service == '1') {
                this.emailSettings.patchValue({
                    host: '',
                    encryption: '',
                    port: '',
                });
            }
            if (service == '2') {
                this.emailSettings.patchValue({
                    host: 'smtp.gmail.com',
                    encryption: 'ssl',
                    port: '465',
                });
            }
        }, 100);
    }

    submitCompanySettings() {
        this.animateLoader();
        this.saveCompanySettingsSaved = 'Saving...';
        const formData = {...this.CompanySettings.value};
        console.log('formData CompanySettings', formData);
        this.UsersService.saveCompanySettings(formData.companyName, formData.companyAddress, formData.licence1, formData.licence2, formData.licence3, formData.licence4).subscribe(response => {
            console.log('response CompanySettings update', response);
            this.animateLoader();
            this.saveCompanySettingsSaved = 'Saved';
            setTimeout(() => {
                this.saveCompanySettingsSaved = 'Save';
            }, 1000);
        });
    }

    submitEmailSettings() {
        this.animateLoader();
        this.saveEmailSettingsSaved = 'Saving...';
        const formData = {...this.emailSettings.value};
        console.log('formData emailSettings', formData);
        this.UsersService.saveEmailSettings(formData.emailService, formData.host, formData.login, formData.password, formData.encryption, formData.port).subscribe(response => {
            console.log('response CompanySettings update', response);
            this.animateLoader();
            this.saveEmailSettingsSaved = 'Saved';
            setTimeout(() => {
                this.saveEmailSettingsSaved = 'Save';
            }, 1000);
        });
    }

    animateLoader() {
        this.hidepreloaderState = this.hidepreloaderState === 'end' ? 'start' : 'end';
    }
}
